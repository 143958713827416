import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.1.3/node_modules/@vercel/analytics/dist/react/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.10_next@14.2.15_react@18.2.0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@opentelemetry+api@1.4.1_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@opentelemetry+api@1.4.1_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/CalSans-SemiBold.ttf\",\"variable\":\"--font-calsans\"}],\"variableName\":\"calSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@opentelemetry+api@1.4.1_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/LondrinaSolid-Regular.ttf\",\"variable\":\"--font-londrina\"}],\"variableName\":\"londrinaSolid\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/blog/nav/nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MouseShadow"] */ "/vercel/path0/src/components/layout/mouse-shadow.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/site.css");
